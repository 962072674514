import { useAdminApi } from '@hooks/useAdminApi';
import { CreateLadderRequest } from '@protos/ladders';
import { Order } from '@protos/trading';
import { toastifyService } from '@services/ToastifyService';
import { useCallback } from 'react';

export const useLaddersApi = () => {
  const { apiClient } = useAdminApi();

  const createLadder = useCallback(
    async (payload: CreateLadderRequest): Promise<Order[] | undefined> => {
      if (!apiClient) return undefined;
      console.log('createLadder is being called');

      try {
        const { symbol, expiry_timestamp, orders, account_id } = payload;
        const ladderRequest: CreateLadderRequest = {
          symbol,
          expiry_timestamp,
          orders,
          account_id,
        };
        const ladders = await apiClient.createNewLadder(ladderRequest);
        toastifyService.showSuccess('Order placed successfully');
        return ladders;
      } catch (error: any) {
        const message = error?.data?.detail?.[0]?.msg || 'Error creating ladder, see console for more details';
        console.error('Error creating ladder:', error);
        toastifyService.showError(message);
        return undefined;
      }
    },
    [apiClient]
  );

  const expireLadder = useCallback(
    async (symbol: string, account_id: string): Promise<void> => {
      if (!apiClient) return;

      try {
        await apiClient.expireExistingLadder(symbol, account_id);
      } catch (error) {
        console.error('Error expiring ladder:', error);
      }
    },
    [apiClient]
  );

  const fetchLaddersForContracts = useCallback(
    async (symbols: string[], user_id: string): Promise<Order[]> => {
      if (!apiClient) return [];

      try {
        const ladders = await apiClient.getLaddersForContracts(symbols, user_id);
        return ladders;
      } catch (error) {
        console.error('Error fetching ladders:', error);
        return [];
      }
    },
    [apiClient]
  );

  const getAllOrdersLoader = useCallback(
    (selectedTradingAccount?: string) => {
      if (!apiClient) return;

      return apiClient.allLaddersLoader(selectedTradingAccount ? { account_id: selectedTradingAccount } : undefined);
    },
    [apiClient]
  );

  return { createLadder, fetchLaddersForContracts, getAllOrdersLoader, expireLadder };
};
